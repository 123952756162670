import './pages.scss'
import Header from './components/Header';
import PieRechartComponent from './PieChart';
import {Link, useNavigate} from "react-router-dom"
import Button from './components/Button';
import { useState, useEffect} from "react"
import axios from 'axios'
import { mdiDeleteCircle } from '@mdi/js';
import { mdiImageEditOutline } from '@mdi/js';
import $ from "jquery"
import Icon from '@mdi/react'
function Gardens({ hide
}) {
    const [result, setResult] = useState("");
    const navigate = useNavigate();
    const addGardens = (e) => {
        navigate('/add-gardens')
      }
    const [garden, getgardens]=useState([])
    const getgarden = () => {
        axios.get('http://localhost:8000/garden-list.php').then((response)=>{
            getgardens(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getgarden();
    },[]);
    const deleteGarden = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        getgarden();
    }
    let dummy=[['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Gardens
        </div>
    </div>
    </>}
    <div className='services-list'>
        <table className='table1'>
            <tr>
                <th>Garden name</th>
                <th>Timings</th>
                <th>Community</th>
                <th>size</th>
                <th>cost</th>
                <th></th>
            </tr>
            {garden.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>{data[4]}</td>
                    <td>
                        <span title='edit'>
                            <Link to='../edit-gardens' state={{name:data[0],timings:data[1],community:data[2],size:data[3],cost:data[4]}}><button type='submit' style={{border:'none'}}><Icon path={mdiImageEditOutline} style={{height:'24px',cursor:'pointer'}} /></button></Link>
                        </span>
                        <form action="http://localhost:8000/delete-garden.php" method="post" onSubmit={(event) => deleteGarden(event)}><span title='delete'><input style={{display:'none'}} name='name' value={data[0]}/><button type='submit' style={{border:'none'}}><Icon path={mdiDeleteCircle} style={{height:'24px',cursor:'pointer',color:'red'}} /></button></span></form>
                    </td>
                </tr>
                );
            })}
            <Button onClick={addGardens} text='Add Gardens'/>
        </table>
        <PieRechartComponent/>
    </div>
  </>
  );
}

export default Gardens;
