import './pages.scss'
import Header from './components/Header';
import StudentsDashboard from './StudentDasboard';
import { useState } from 'react'
import Rentals from './RentalList';
import ServicesList from './ServicesList';
import Gardens from './Gardens';
function Owner({
}) {
    const [selected, setSelected]=useState(1)
    const school = () => {
        setSelected(1)
    }
    const business = () => {
        setSelected(2)
    }
    const student = () => {
        setSelected(3)
    }
  return (
    <>
        <Header type='admin'/>
        <div className='contact-wrapper'>
        <div className='contact-header'>
            Owner Dashboard
        </div>
        </div>
        <div className='admin-wrapper'>
            <div className='partition1'>
                {selected===1 ? <div onClick={school} className='selected'>Rentals</div>:<div onClick={school} >Rentals</div>}
                {selected===2 ? <div onClick={business} className='selected'>Services</div>:<div onClick={business} >Services</div>}
                {selected===3 ? <div onClick={student} className='selected'>Gardens</div>:<div onClick={student} >Gardens</div>}
            </div>
            <div className='partition2'>
                {selected===1 && <Rentals hide/>}
                {selected===2 && <ServicesList hide/>}
                {selected===3 && <Gardens hide/>}
            </div>
        </div>
    </>
  );
}

export default Owner;
