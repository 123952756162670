import './pages.scss'
import Header from './components/Header'
import { useState, useEffect} from "react"
import axios from 'axios'
import Button from './components/Button'
import $ from "jquery"
import {useSelector} from 'react-redux'

function ServicesList({
    hide
}) {
    const dataSelect = useSelector(state => state.logData)
    let data1=dataSelect[dataSelect.length-1]
    const [result, setResult]=useState([])
    const [service, getservices]=useState([])
    const [ammenity, getAmenitys]=useState([])
    const getservice = () => {
        axios.get('http://localhost:8000/service-list.php').then((response)=>{
            getservices(response.data)
        }).catch(error=>console.error('Error'))
    }
    const getAmenity = () => {
        axios.get('http://localhost:8000/ammenities-list.php').then((response)=>{
            getAmenitys(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getservice()
        getAmenity()
    },[])
    const availService = (e) => {
        e.preventDefault()
        const form = $(e.target)
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data)
            },
        })
        getservice()
        getAmenity()
    }
    let dummy=[['Electricity',500,'10%','January'],['Water',500,'10%','January'],['Gas',500,'10%','October'],['Internet',700,'10%','January']]
    let dummy1=[['Swiming Pools',500,'10%','January'],['Clubhouses',500,'10%','January'],['Tennis courts',500,'10%','October'],['Fitness facilities',700,'10%','January'],['Parking',500,'10%','October'],['Covered parking',500,'10%','October'],['Parks',500,'10%','October'],['Common areas',500,'10%','October'],['Pet areas',500,'10%','October'],['Security',500,'10%','October'],['Package lockers',500,'10%','October'],['bike storage',500,'10%','October'],['Co-working spaces',500,'10%','October'],['electric car charging stations',500,'10%','October'],['Kids play area',500,'10%','October'],['Barbeque areas',500,'10%','October'],['Picnic tables',500,'10%','October'],['Community garden spaces',500,'10%','October'],['Green spaces',500,'10%','October'],['Elevarors',500,'10%','October']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Services List
        </div>
    </div></>}
    <div className='services-list'>
        <div className='half'>
            <h3>Services</h3>
            <table className='table1'>
            <tr>
                <th>Service Name</th>
                <th>Cost</th>
                <th>Tax</th>
                <th>Month</th>
                <th></th>
            </tr>
            {service.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>
                    <form action="http://localhost:8000/avail-service.php" method="post" onSubmit={(event) => availService(event)}>
                        <input style={{display:'none'}} name='name' value={data[0]}/><input style={{display:'none'}} name='id' value={data1.userId}/>
                        <Button text='Avail this service' type='submit'/>
                    </form>
                    </td>
                </tr>
                )
            })}
        </table>
        </div>
        <div className='half'>
            <h3>Amenities</h3>
            <table className='table1'>
            <tr>
                <th>Ammenity Name</th>
                <th>Cost</th>
                <th>Tax</th>
                <th>Month</th>
                <th></th>
            </tr>
            {ammenity.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td><form action="http://localhost:8000/avail-ammenity.php" method="post" onSubmit={(event) => availService(event)}>
                        <input style={{display:'none'}} name='name' value={data[0]}/><input style={{display:'none'}} name='id' value={data1.userId}/>
                        <Button text='Avail this Ammenity' type='submit'/>
                    </form></td>
                </tr>
                )
            })}
        </table>
        </div>
    </div>
  </>
  )
}

export default ServicesList
