import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';

function Rentals({hide
}) {
    let dummy=[['Emili','440','20-09-2022','600'],['Emili','440','20-09-2022','600'],['Emili','440','20-09-2022','600'],['Emili','440','20-09-2022','600']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Rentals
        </div>
    </div></>}
    <div className='services-list'>
        <table className='table1'>
            <tr>
                <th>Name</th>
                <th>Building no</th>
                <th>Staying from</th>
                <th>Total due</th>
            </tr>
            {dummy.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                </tr>
                );
            })}
        </table>
    </div>
  </>
  );
}

export default Rentals;
