import Header from "./components/Header"
import maps from '../images/map.png'
import parking from '../images/parking.webp'
function Maps() {
  return (
    <>
    <Header/>
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Maps
        </div>
    </div>
    <div className="maps">
        <img src={maps} className='img' alt='image loading'/>
        <h4>Available Parking slots</h4>
        <select  required name="gender">
            <option hidden value=' ' disabled selected >Check available slots</option>
            <option>A 621</option>
            <option>A 617</option>
            <option>A 616</option>
            <option>A 615</option>
            <option>A 612</option>
            <option>A 610</option>
            <option>A 608</option>
            <option>A 606</option>
            <option>A 644</option>
            <option>A 643</option>
            <option>A 623</option>
            <option>A 622</option>
        </select>
        <img src={parking} className='img' alt='image loading'/>
    </div>
    </>
  ) 
}

export default Maps