import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect, useCallback } from "react"

function Requests({
}) {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Requests or Incidents
        </div>
    </div>
    <div className='login-wrapper'>
        <form>
            <div className='login forgot-password'>
                <h2>Register Incidents</h2>
                <Input type='text' placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required/>
                <Input type='email' placeholder='Email' name='emailId' value={email} onChange={(event) => handleEmail(event)} required />
                <Input type='number' placeholder='Phone Number'/>
                <textarea placeholder='Request or Incident Details'/>
                <Button text='Submit' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default Requests;
