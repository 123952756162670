import './pages.scss'
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect} from "react"
import axios from 'axios'
function Visitors({
    hide
}) {
    const [visitor, getvisitors]=useState([])
    const getvisitor = () => {
        axios.get('http://localhost:8000/visitor-list.php').then((response)=>{
            getvisitors(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getvisitor();
    },[]);
    let dummy=[['Paul','Pool Manager','abc pool','xyz'],['Mathew','Garden Manager','new garden','jkl'],['John','Building Manager','123 building','xyz']]
    return (
  <>
    { !hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Visitors
        </div>
    </div></>}
    <div className='services-list'>
        <table className='table1'>
            <tr>
                <th>Visitors name</th>
                <th>Date of Birth</th>
                <th>Time</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Model</th>
                <th>Vehicle</th>
                <th>Driving Licence</th>
            </tr>
            {visitor.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>{data[4]}</td>
                    <td>{data[5]}</td>
                    <td>{data[6]}</td>
                    <td>{data[7]}</td>
                </tr>
                );
            })}
        </table>
    </div>
  </>
  );
}

export default Visitors;
