import './pages.scss'
import Header from './components/Header';
import $ from "jquery"
import Input from './components/Input'
import { useState, useEffect} from "react"
import Button from './components/Button';
import axios from 'axios'
import RadarChart from './RadarChart';
import { mdiDeleteCircle } from '@mdi/js';
import { mdiImageEditOutline } from '@mdi/js';
import { useNavigate } from "react-router-dom";
import Icon from '@mdi/react'
import {Link} from "react-router-dom"

function Pools({
    hide
}) {
    const [result, setResult] = useState("");
    const [pool, getpools]=useState([])
    const navigate = useNavigate();
    const getpool = () => {
        axios.get('http://localhost:8000/pool-list.php').then((response)=>{
            getpools(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getpool();
    },[]);
    const addPools = (e) => {
        navigate('/add-pools')
      }
      const deleteService = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        getpool();
    }
    let dummy=[['abc pool','monday to sunday 7am to 8pm','abc, xyz, mno','ybk reddy'],['abc pool','monday to sunday 7am to 8pm','abc, xyz, mno','ybk reddy'],['abc pool','monday to sunday 7am to 8pm','abc, xyz, mno','ybk reddy'],['abc pool','monday to sunday 7am to 8pm','abc, xyz, mno','ybk reddy']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Pools
        </div>
    </div></>}
    <div className='services-list'>
        <table className='table1'>
            <tr>
                <th>Pool name</th>
                <th>Timings</th>
                <th>Reserved by</th>
                <th>incharge name</th>
                <th></th>
            </tr>
            {pool.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>         
                        <span title='edit'>
                            <Link to='../edit-pool' state={{name:data[0],time:data[1],reservedBy:data[2],incharge:data[3]}}><button type='submit' style={{border:'none'}}><Icon path={mdiImageEditOutline} style={{height:'24px',cursor:'pointer'}} /></button></Link>
                        </span>
                        <form action="http://localhost:8000/delete-pool.php" method="post" onSubmit={(event) => deleteService(event)}><span title='delete'><input style={{display:'none'}} name='name' value={data[0]}/><button type='submit' style={{border:'none'}}><Icon path={mdiDeleteCircle} style={{height:'24px',cursor:'pointer',color:'red'}} /></button></span></form>
                    </td>
                </tr>
                );
            })}
        <Button onClick={addPools} text='Add pool'/>

        </table>
        <div style={{margin:'30px'}}><RadarChart/></div>
    </div>
  </>
  );
}

export default Pools;
