import './pages.scss'
import Label from './components/Label';
import Header from './components/Header';
import Input from './components/Input'
import Button from './components/Button';
import { useState, useEffect} from "react"
import $ from "jquery"
import { useNavigate, useLocation } from 'react-router-dom';

function EditPools({
}) {
    const location=useLocation();
    const navigate = useNavigate();
    const [name, setName] = useState(location.state.name)
    const [inchargeName, setinchargeName] = useState(location.state.incharge)
    const [timings, settimings] = useState(location.state.time)
    const [reservedBy, setreservedBy] = useState(location.state.reservedBy)
    const [result, setResult] = useState("");
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleinchargeName = (e) => {
        setinchargeName(e.target.value);
    }
    const handletimings = (e) => {
        settimings(e.target.value);
    }
    const handlereservedBy = (e) => {
        setreservedBy(e.target.value);
    }
    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        navigate('../pools')
    };
    useEffect(() => {
        if(result==='true'){
            alert('Pool Edited successfully')
            setName("")
            settimings("")
            setreservedBy("")
            setinchargeName("")
            navigate('../pools')
        }
        if(result==='false') {
            alert("Failed to Edit")
        }
    },[ result])
  return (
  <>
    <Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Edit Pools
        </div>
    </div>
    <div className='login-wrapper'>
        <form  onSubmit={(event) => handleSumbit(event)}
            action="http://localhost:8000/edit-pools.php"
            method="post" >
            <div className='login forgot-password'>
                <h2>Edit Pools</h2> 
                <Label text={location.state.name}/>
                <Input type='text' style={{display:'none'}} placeholder='Name' name="name" value={name} onChange={(event) => handleName(event)} required />
                <Input type='text' placeholder='Timings' name="timings" value={timings} onChange={(event) => handletimings(event)} required />
                <Input type='text' placeholder='Reserved By' name="reservedBy" value={reservedBy} onChange={(event) => handlereservedBy(event)} required />
                <Input type='text' placeholder='Incharge Name' name="inchargeName" value={inchargeName} onChange={(event) => handleinchargeName(event)} required />
                <Button text='Edit Pools' type='submit'/>
            </div>
        </form>
    </div>
  </>
  );
}

export default EditPools;
