import Header from './components/Header'
import Label from './components/Label';
import Search from './components/Search';
import buildings from '../images/build.jpg'
import visitors from '../images/visitors.jpg'
import garden from '../images/garden.jpg'
function MercadoEscolar() {
  return (
    <>
        <Header/>
        <div className='mercado-escolar'>
          <div className='partition1'>
            <Label text='This is a one stop place to manage your community'/>
            <h2 className='heading'> Welcome to Terrazas de <br/> Guacuco!</h2>
            <div className='search-div'><Search/></div>
          </div>
          <div className='about-wrapper'>
          <div className='partition2'>
            <div className='content-div'>
                <span className='heading'>About Terrazas de Guacuco</span>
                <p className='text'>Terrazas de Guacuco, is a huge complex, it has 367 residential units classified as Studios,
                Town Houses, and cabins. It has two swimming pools, several gardens, tennis courts and
                several walking trials. There are several managers at the complex, manager garden
                manager, pool manager, building manager. There is a high security at the complex that
                works 24 hrs. a day. Complex has about 200 watchmen. Complex has owners, short term
                rentals and long term rentals and visitors. Anyone coming to the complex has to register
                his/her visit. Registration includes drivers, plate car, drivers license and DL for each person
                in the car. Even if you get in walking, you have to register. Visitors need to register and also
                need driving instructions, hence we have to use positioning coordinates to guide
                them(GPS).</p>
            </div>
          </div>
          </div>
          <div className='Services-wrapper'>
            <div className='Services-body'>
            <div className='grid-container'>
              <div class="grid-item">
                <img src={buildings} className='img' alt='image loading'/>
              </div>
              <div class="grid-item">
                <span className='content'>Management of Gardens and Surrondings</span>
                <p className='cont'>This application makes it very easy to manage gardens and Surrondings. You can easily find out the list of gardens around you along with their size and cost.</p>
              </div>
              <div class="grid-item">
                <span className='content'>Manage buildings</span>
                <p className='cont'>This application helps you to manage your buildings in several ways. It provides you the list of amenities and you can avail those services from application.</p>
                <p></p>
              </div>
              <div class="grid-item">
                <img src={garden} className='img' alt='image loading'/>
              </div>
              <div class="grid-item">
                <img src={visitors} className='img' alt='image loading'/>
              </div>              
              <div class="grid-item">
                <span className='content'>Management of visitors</span>
                <p className='cont'>This application provides you an option to add your visitors and get it approved by the managers. So this makes it easier for both the residemts and managers to track who is entering the community</p>
              </div>
            </div>
            </div>
          </div>
        </div>
    </>
  ); 
}

export default MercadoEscolar;
