import './pages.scss'
import Header from './components/Header';
import PieRechartComponent from './PieChart';
import {Link, useNavigate} from "react-router-dom"
import Button from './components/Button';
import { useState, useEffect} from "react"
import axios from 'axios'
import { mdiDeleteCircle } from '@mdi/js';
import { mdiImageEditOutline } from '@mdi/js';
import $ from "jquery"
import {useSelector} from 'react-redux'
import Icon from '@mdi/react'
function ResidentsGardens({ hide
}) {
    const dataSelect = useSelector(state => state.logData)
    let data1=dataSelect[dataSelect.length-1]
    const [result, setResult] = useState("");
    const navigate = useNavigate();
    const addGardens = (e) => {
        navigate('/add-gardens')
      }
    const [garden, getgardens]=useState([])
    const getgarden = () => {
        axios.get('http://localhost:8000/garden-list.php').then((response)=>{
            getgardens(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getgarden();
    },[]);
    const deleteGarden = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        getgarden();
    }
    let dummy=[['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600'],['Chapora Garden','Emili','440sq','600']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Gardens
        </div>
    </div>
    </>}
        <table className='table1'>
            <tr>
                <th>Garden name</th>
                <th>Timings</th>
                <th>Community</th>
                <th>size</th>
                <th>cost</th>
                <th></th>
            </tr>
            {garden.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>{data[4]}</td>
                    <td>
                        <form action="http://localhost:8000/use-garden.php" method="post" onSubmit={(event) => deleteGarden(event)}>
                            <input style={{display:'none'}} name='name' value={data[0]}/>
                            <input style={{display:'none'}} name='id' value={data1.userId}/>
                            <Button type='submit' text='Use Garden'/></form>
                    </td>
                </tr>
                );
            })}
        </table>
  </>
  );
}

export default ResidentsGardens;
