import React from 'react';
import { Radar, RadarChart, PolarGrid, 
    PolarAngleAxis, PolarRadiusAxis } from 'recharts';
  
const RadarChart1 = () => {
  
    // Sample data
    const data = [
        { name: 'Pool A', x: 21 },
        { name: 'Pool B', x: 22 },
        { name: 'Pool C', x: -32 },
        { name: 'Pool D', x: -14 },
        { name: 'Pool E', x: -51 },
        { name: 'Pool F', x: 16 },
        { name: 'Pool G', x: 7 },
        { name: 'Pool H', x: -8 },
        { name: 'Pool I', x: 9 },
    ];
  
    return (
        <RadarChart height={350} width={350} 
            outerRadius="80%" data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis />
            <Radar dataKey="x" stroke="#3b4554" 
                fill="#4cbfa6" fillOpacity={0.5} />
        </RadarChart>
    );
}
  
export default RadarChart1;