import './pages.scss'
import Header from './components/Header';
import { useState, useEffect} from "react"
import axios from 'axios'
import Button from './components/Button';
import { mdiDeleteCircle } from '@mdi/js';
import { mdiImageEditOutline } from '@mdi/js';
import $ from "jquery"
import Icon from '@mdi/react'
import {Link, useNavigate} from "react-router-dom"
function AdminServiceDashboard({
    hide
}) {
    const [result, setResult] = useState("");
    const [service, getservices]=useState([])
    const [ammenity, getAmenitys]=useState([])
    const navigate = useNavigate();
    const getservice = () => {
        axios.get('http://localhost:8000/service-list.php').then((response)=>{
            getservices(response.data)
        }).catch(error=>console.error('Error'))
    }
    const getAmenity = () => {
        axios.get('http://localhost:8000/ammenities-list.php').then((response)=>{
            getAmenitys(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getservice();
        getAmenity();
    },[]);
    const addServices = (e) => {
        navigate('/add-services')
      }
      const addAmmenities = (e) => {
        navigate('/add-ammenities')
      }
    const deleteService = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        getservice();
        getAmenity();
    }
    let dummy=[['Electricity',500,'10%','January'],['Water',500,'10%','January'],['Gas',500,'10%','October'],['Internet',700,'10%','January']]
    let dummy1=[['Swiming Pools',500,'10%','January'],['Clubhouses',500,'10%','January'],['Tennis courts',500,'10%','October'],['Fitness facilities',700,'10%','January'],['Parking',500,'10%','October'],['Covered parking',500,'10%','October'],['Parks',500,'10%','October'],['Common areas',500,'10%','October'],['Pet areas',500,'10%','October'],['Security',500,'10%','October'],['Package lockers',500,'10%','October'],['bike storage',500,'10%','October'],['Co-working spaces',500,'10%','October'],['electric car charging stations',500,'10%','October'],['Kids play area',500,'10%','October'],['Barbeque areas',500,'10%','October'],['Picnic tables',500,'10%','October'],['Community garden spaces',500,'10%','October'],['Green spaces',500,'10%','October'],['Elevarors',500,'10%','October']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Admin Service Dashboard
        </div>
    </div></>}
    <div className='services-list'>
        <div className='half'>
            <h3>Services</h3>
            <table className='table1'>
            <tr>
                <th>Service Name</th>
                <th>Cost</th>
                <th>Tax</th>
                <th>Month</th>
                <th></th>
            </tr>
            {service.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>         
                        <span title='edit'>
                            <Link to='../edit-service' state={{name:data[0],cost:data[1],tax:data[2],month:data[3]}}><button type='submit' style={{border:'none'}}><Icon path={mdiImageEditOutline} style={{height:'24px',cursor:'pointer'}} /></button></Link>
                        </span>
                        <form action="http://localhost:8000/delete-service.php" method="post" onSubmit={(event) => deleteService(event)}><span title='delete'><input style={{display:'none'}} name='name' value={data[0]}/><button type='submit' style={{border:'none'}}><Icon path={mdiDeleteCircle} style={{height:'24px',cursor:'pointer',color:'red'}} /></button></span></form>
                    </td>
                </tr>
                );
            })}
            <Button onClick={addServices} text='Add Services'/>
        </table>
        </div>
        <div className='half'>
            <h3>Amenities</h3>
            <table className='table1'>
            <tr>
                <th>Ammenity Name</th>
                <th>Cost</th>
                <th>Tax</th>
                <th>Month</th>
                <th></th>
            </tr>
            {ammenity.map(data => {
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>
                        <span title='edit'>
                            <Link to='../edit-ammenities' state={{name:data[0],cost:data[1],tax:data[2],month:data[3]}}><button type='submit' style={{border:'none'}}><Icon path={mdiImageEditOutline} style={{height:'24px',cursor:'pointer'}} /></button></Link>
                        </span>
                        <form action="http://localhost:8000/delete-ammenity.php" method="post" onSubmit={(event) => deleteService(event)}><span title='delete'><input style={{display:'none'}} name='name' value={data[0]}/><button type='submit' style={{border:'none'}}><Icon path={mdiDeleteCircle} style={{height:'24px',cursor:'pointer',color:'red'}} /></button></span></form>
                    </td>
                </tr>
                );
            })}
            <Button onClick={addAmmenities} text='Add Ammenties'/>
        </table>
        </div>
    </div>
  </>
  );
}

export default AdminServiceDashboard;
