import './pages.scss'
import Header from './components/Header';
import { useState, useEffect} from "react"
import axios from 'axios'
import Button from './components/Button';
import $ from "jquery"
import {useSelector} from 'react-redux'

function ResidentsDashboard({
    hide
}) {
    const dataSelect = useSelector(state => state.logData);
    let data1=dataSelect[dataSelect.length-1]
    const [result, setResult]=useState([])
    const [service, getservices]=useState([])
    const [ammenity, getAmenitys]=useState([])
    const getservice = () => {
        axios.get('http://localhost:8000/service-list.php').then((response)=>{
            getservices(response.data)
        }).catch(error=>console.error('Error'))
    }
    const getAmenity = () => {
        axios.get('http://localhost:8000/ammenities-list.php').then((response)=>{
            getAmenitys(response.data)
        }).catch(error=>console.error('Error'))
    }
    const [garden, getgardens]=useState([])
    const getgarden = () => {
        axios.get('http://localhost:8000/garden-list.php').then((response)=>{
            getgardens(response.data)
        }).catch(error=>console.error('Error'))
    }
    const [pool, getpools]=useState([])
    const getpool = () => {
        axios.get('http://localhost:8000/pool-list.php').then((response)=>{
            getpools(response.data)
        }).catch(error=>console.error('Error'))
    }
    useEffect(()=> {
        getservice();
        getAmenity();
        getgarden();
        getpool();
    },[]);
    const availService = (e) => {
        alert('ho')
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                setResult(data);
            },
        });
        getservice();
        getAmenity();
    }
    let dummy=[['Electricity',500,'10%','January'],['Water',500,'10%','January'],['Gas',500,'10%','October'],['Internet',700,'10%','January']]
    let dummy1=[['Swiming Pools',500,'10%','January'],['Clubhouses',500,'10%','January'],['Tennis courts',500,'10%','October'],['Fitness facilities',700,'10%','January'],['Parking',500,'10%','October'],['Covered parking',500,'10%','October'],['Parks',500,'10%','October'],['Common areas',500,'10%','October'],['Pet areas',500,'10%','October'],['Security',500,'10%','October'],['Package lockers',500,'10%','October'],['bike storage',500,'10%','October'],['Co-working spaces',500,'10%','October'],['electric car charging stations',500,'10%','October'],['Kids play area',500,'10%','October'],['Barbeque areas',500,'10%','October'],['Picnic tables',500,'10%','October'],['Community garden spaces',500,'10%','October'],['Green spaces',500,'10%','October'],['Elevarors',500,'10%','October']]
    return (
  <>
    {!hide && <><Header />
    <div className='contact-wrapper'>
        <div className='contact-header'>
            Residents Dashboard
        </div>
    </div></>}
    <div className='services-list'>
        <div className='half'>
            <h3>Services</h3>
            <table className='table1'>
            <tr>
                <th>Service Name</th>
                <th>Cost</th>
                <th>Tax</th>
                <th>Month</th>
                {/* <th></th> */}
            </tr>
            {service.map(data => {
                if(data1.userId==data[4])
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    {/* <td><Button text='Avail this Service'/></td> */}

                </tr>
                );
            })}
        </table>
        <h3>Gardens</h3>
        <table className='table1'>
            <tr>
                <th>Garden name</th>
                <th>Timings</th>
                <th>Community</th>
                <th>size</th>
                <th>cost</th>
            </tr>
            {garden.map(data => {
                if(data1.userId==data[5])
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    <td>{data[4]}</td>
                </tr>
                );
            })}
        </table>
        </div>
        <div className='half'>
            <h3>Amenities</h3>
            <table className='table1'>
            <tr>
                <th>Ammenity Name</th>
                <th>Cost</th>
                <th>Tax</th>
                <th>Month</th>
                {/* <th></th> */}
            </tr>
            {ammenity.map(data => {
                if(data1.userId==data[4])
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                    {/* <td><Button text='Avail this ammenity'/></td> */}
                </tr>
                );
            })}
        </table>
        <h3>Pools</h3>
        <table className='table1'>
            <tr>
                <th>Pool name</th>
                <th>Timings</th>
                <th>Reserved by</th>
                <th>incharge name</th>
            </tr>
            {pool.map(data => {
                if(data1.userId==data[4])
                return (
                <tr>
                    <td>{data[0]}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>
                    <td>{data[3]}</td>
                </tr>
                );
            })}

        </table>
        </div>
    </div>
  </>
  );
}

export default ResidentsDashboard;
