import './pages.scss'
import Header from './components/Header';
import buildings from '../images/build.jpg'
import visitors from '../images/visitors.jpg'
import garden from '../images/garden.jpg'
function Services({
}) {
  return (
  <>
    <Header/>
    <div className='Services-wrapper'>
        <div className='Services-header'>
            Services
        </div>
        <div className='Services-body'>
        <div className='grid-container'>
              <div class="grid-item">
                <img src={buildings} className='img' alt='image loading'/>
              </div>
              <div class="grid-item">
                <span className='content'>Management of Gardens and Surrondings</span>
                <p className='cont'>This application makes it very easy to manage gardens and Surrondings. You can easily find out the list of gardens around you along with their size and cost.</p>
              </div>
              <div class="grid-item">
                <span className='content'>Manage buildings</span>
                <p className='cont'>This application helps you to manage your buildings in several ways. It provides you the list of amenities and you can avail those services from application.</p>
                <p></p>
              </div>
              <div class="grid-item">
                <img src={garden} className='img' alt='image loading'/>
              </div>
              <div class="grid-item">
                <img src={visitors} className='img' alt='image loading'/>
              </div>              
              <div class="grid-item">
                <span className='content'>Management of visitors</span>
                <p className='cont'>This application provides you an option to add your visitors and get it approved by the managers. So this makes it easier for both the residemts and managers to track who is entering the community</p>
              </div>
            </div>
        </div>
    </div>
  </>
  );
}

export default Services;
