import './pages.scss'
import Header from './components/Header';
import Button from './components/Button';
function About({
}) {
    const blogPage = () => {
        window.open("http://bxy4670.uta.cloud/")
    }
  return (
    <>
    <Header/>
    <div className='about-wrapper'>
        <div className='partition1'>
            <div className='heading'>
                Welcome to <br/>Terrazas de Guacuco
                <p className='text'>This is a one stop place to manage your community, residents and visitors</p>
            </div>
            <div className='button-div' onClick={blogPage}><Button text='Visit Blog page'/></div>
        </div>
        <div className='partition2'>
            <div className='content-div'>
                <span className='heading'>About Terrazas de Guacuco</span>
                <p className='text'>Terrazas de Guacuco, is a huge complex, it has 367 residential units classified as Studios, Town Houses, and cabins. It has two swimming pools, several gardens, tennis courts and several walking trials. There are several managers at the complex, manager garden manager, pool manager, building manager. There is a high security at the complex that works 24 hrs. a day. Complex has about 200 watchmen. Complex has owners, short term rentals and long term rentals and visitors. Anyone coming to the complex has to register his/her visit. Registration includes drivers, plate car, drivers license and DL for each person in the car. Even if you get in walking, you have to register. Visitors need to register and also need driving instructions, hence we have to use positioning coordinates to guide them(GPS).
                </p>
            </div>
        </div>
    </div>
    </>
  );
}

export default About;
